<template>
    <div id='namen' className='p-1 mt-2 grid grid-col gap-4 ml-4 pb-2'>
      <!-- RegestenEditor Headline, Regest + index of Regest and Buttons to go back to EntriesOverview and Save Regest -->
      <span className="flex flex-row justify-between border-b-2 border-gray-200 pb-2">
            <h3 className='mt-auto mb-auto font-semibold'>Regest:</h3>
            <span>
                <button type="button"
                    className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20"
                    @click="goTo">Zurück</button>
                <button type="button" @click="toggleDeletePopup()"
                    className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Löschen</button>
                <button type="button"
                    className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto w-fit"
                    @click="saveRegest('regest')">Anwenden</button>
            </span>
        </span>


      <!--Inputfield for Regestentext -->
        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label :for='"regesttext"' className='mt-auto mb-auto font-semibold'>Text:</label>
            <textarea rows="20"
                className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 col-span-5 lg:col-span-4'
                :id='"regesttext"' name='text' v-model="regestenStore.active_data.text" />
        </div>
      <!--Inputfield for Regestennotes -->
      <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
        <label for='notes' className='mt-auto mb-auto font-semibold'>Notizen:</label>
        <textarea rows="4"
                  className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-5 lg:col-span-4'
                  id='notes' name='notes' v-model="regestenStore.active_data.note" />
      </div>

      <!--Input for Dating -->
        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='normdate' className='mt-auto mb-auto  font-semibold'>Datum (Normal):</label>
            <select v-model="regestenStore.active_data.date.normalizeddate.type" @change="changeDateType"
                className="mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-2 col-start-2 w-full">
                <option v-for="(key, value) in datetypes" :key="key" :value="value">{{ key }}
                </option>
            </select>
            <div v-if="regestenStore.active_data.date.normalizeddate.type == 'when'" class="my-auto">
                <input type='date'
                    className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                    id='normdate' name='normdate' v-model="regestenStore.active_data.date.normalizeddate.when" />
            </div>
            <div v-else-if="regestenStore.active_data.date.normalizeddate.type == 'fromto'" class="my-auto">
                <input type="date"
                    className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                    id='normdate' name='normdate' v-model="regestenStore.active_data.date.normalizeddate.from" />
                <input type="date"
                    className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                    id='normdate' name='normdate' v-model="regestenStore.active_data.date.normalizeddate.to" />
            </div>
        </div>

      <!--Inputfield for Date Notes -->
      <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label :for='"datenotice"' className='mt-auto mb-auto font-semibold'>Notizen zum Datum:</label>
            <textarea
                className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-5 lg:col-span-4'
                :id='"datenotice"' name='text' v-model="regestenStore.active_data.date.note" />
        </div>
      <!--Inputfield for Original Date like they are in Regesten -->
      <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='origdate' className='mt-auto mb-auto  font-semibold'>Datum (Original):</label>
            <input type='text'
                className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                id='origdate' name='origdate' v-model="regestenStore.active_data.date.oridate" />
        </div>


      <!--Legelterms -->
        <span className="grid grid-cols-7 grid-flow-row">
            <label className='mt-auto mb-auto font-semibold'>Rechtsbegriffe:</label>
            <button v-on:click="regestenStore.addLegalTerm" type="button"
                className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto col-start-3 lg:col-start-2 w-full">Begriff
                hinzufügen</button>
        </span>
        <div v-for="(term, index) in regestenStore.active_data.legalterms" className="grid grid-cols-7 grid-flow-row pt-3"
            :key="term">
            <select v-model="regestenStore.active_data.legalterms[index]"
                className="mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-2 col-start-2 w-full">
              <option v-for="legalterm in legalterms" :key="legalterm">{{legalterm}}</option>
            </select>
            <button v-on:click="regestenStore.removeLegalTerm(index)" type="button"
                className="hover:bg-gray-200 outline-none mr-4 ml-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto w-20">Entfernen</button>
        </div>

      <!--Bibliographie -->
        <div className="pt-4 pb-4 whitespace-pre-line border-y-2 border-blue-800">
            <h3 className='mt-auto mb-auto font-semibold'>Bibliografie:</h3>
            <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
                <button v-on:click="regestenStore.addBibliographie()" type="button"
                  className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto col-start-2 w-full">
                    Hinzufügen
                </button>
            </div>
          
            <div v-for="(bibl, index) in regestenStore.active_data.bibls" :key="index">

                <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
                    <label for='fname' className='mt-auto mb-auto font-semibold'>Quelle:</label>
                    <input type='text'
                        className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                        :id="'source' + index" name='origdate' v-model="bibl.source" />
                </div>
                <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">

                    <label for='fname' className='mt-auto mb-auto font-semibold'>Typ:</label>
                    <select className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                        v-model="bibl.type">
                        <option v-for="typ in sourcetypes" :key="typ" :value="typ">{{ typ }}</option>
                    </select>
                </div>

                <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
                    <label for='normName' className='mt-auto mb-auto  font-semibold'>Distrikt:</label>
                    <select className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                        v-model="bibl.district.name" @change="regestenStore.setDistrictNr(regestenStore.active_data, index)">
                        <option v-for="(key, value) in districts" :key="key" :value="value">{{ key }}</option>
                    </select>
                </div>

                <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
                    <label for='bibnotes' className='mt-auto mb-auto  font-semibold'> Notizen zur Bibliografie:</label>
                    <textarea rows="4"
                        className='mr-4 border-blue-800 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-5 lg:col-span-4'
                        id='bibnotes' name='bibnotes' v-model="bibl.note" />
                </div>
                <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
                    <button v-on:click="regestenStore.removeBibliographie(index)" type="button"
                      className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md col-start-2 border-blue-800 p-1 mt-2 mb-2 w-full">
                      Entferne {{bibl.source}}
                    </button>
                </div>
            </div>
        </div>


        <!--Topographie -->
        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label className='mt-auto mb-auto font-semibold'>Topografie:</label>
            <button v-on:click="showTopoPopup = true" type="button"
                className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto col-start-2 w-fit"
                @click="showTopoPopup = true">Topo hinzufügen
            </button>
        </div>

        <div className="grid grid-cols-7 grid-flow-row pt-3">
            <div class="col-start-2 lg:col-start-2 col-span-3 lg:col-span-2 flex flex-col"
                v-for="(place, index) in regestenStore.active_data.places" :key="index">
                <label className='mt-auto mb-auto font-semibold'>Name:</label>
                <input className='mr-4 border-gray border-2 rounded-md border-gray p-1 mt-2 mb-auto col-span-2 col-start-2'
                    v-model="place.text" />
                <label className='mt-auto mb-auto font-semibold'>Keussen: {{ topoListStore.getTopoWithId(place.id).keussen
                }}</label>
                <label className='mt-auto mb-auto font-semibold'>Map: {{ place.mapid }}
                </label>
                <button v-on:click="regestenStore.removeTopographie(index)" type="button"
                    className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-2 mb-2 w-fit">Entfernen</button>
            </div>
           </div>
        <!-- persons -->
      <div class="grid grid-cols-10 pt-4 pb-4 whitespace-pre-line border-y-2 border-blue-800">
        <span class="col-start-1 mt-auto mb-auto font-semibold">Personen:</span>
        <div class="grid grid-cols-subgrid grid-cols-12 col-span-10">
          <p className='col-start-1 mt-auto mb-auto font-semibold'>Typ</p>
          <p className='col-start-2 mb-auto font-semibold'>Nr</p>
          <p className='col-start-3 mt-auto mb-auto font-semibold'>Gestorben</p>
          <p className='col-start-4 col-span-7 mt-auto mb-auto font-semibold'>Personenname</p>
        </div>
        <div class="grid grid-cols-subgrid grid-cols-12 col-span-10 gap-1" v-for="(person, index) in regestenStore.active_data.persons" :key="person" :id="'person_' + index" >
          <select class="content-center col-start-1" v-model="regestenStore.active_data.persons[index].reftype.type">
            <option value="name">name</option>
            <option value="eltern">eltern</option>
            <option value="nr">nr</option>
            <option value="geschwister">geschwister</option>
            <option value="kind">kind</option>
            <option value="verwandte">verwandte</option>
            <option value="partner">partner</option>
            <option value="other">other</option>
          </select>
          <input v-if="regestenStore.active_data.persons[index].reftype.type === 'nr' ||
                       regestenStore.active_data.persons[index].reftype.type === 'partner' ||
                       regestenStore.active_data.persons[index].reftype.type === 'geschwister' ||
                       regestenStore.active_data.persons[index].reftype.type === 'kinder' ||
                       regestenStore.active_data.persons[index].reftype.type === 'other'" v-model="regestenStore.active_data.persons[index].reftype.nr" class="col-start-2 hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto" type="text">
          <input class="col-start-3 appearance-none bg-white mb-auto
                       text-black w-5 h-5 border-2 border-black rounded -translate-y-0.075 grid place-content-center before:w-4 before:h-4 before:scale-0
                       before:ease-in-out before:duration-150 before:shadow-check-regest checked:before:scale-50 hover:shadow-check-hover mt-auto"
                 v-model="regestenStore.active_data.persons[index].dead"
                 type="checkbox"
          />
          <span className='col-start-4 mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-5'>
            {{personListStore.getPersonOverId(person.id).name}}
          </span>

          <span className='col-start-9 mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-2'>
            {{personListStore.getPersonOverId(person.id).idno}}
          </span>
          <button v-on:click="regestenStore.removePerson(index)" type="button"
                  className="col-start-11 hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto w-10">-</button>
          <button type="button" @click="changeeditor(person.id)"
                  className="col-start-12 hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto w-20">Editieren</button>
        </div>
        <button v-on:click="togglePersonPopup()" type="button"
                className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-8 mb-8 w-full">Person
          hinzufügen</button>
      </div>

      <div class="grid grid-cols-7 gap-1">
        <span class="col-start-1  mt-auto mb-auto font-semibold">Konvente:</span>
        <div v-for="(convent) in regestenStore.active_data.convents" :key="convent"
             class=" col-start-1 grid grid-cols-subgrid col-span-7 grid-cols-7">
          <span class="col-start-1 col-span-6 mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto">{{getConventName(convent.id)}}</span>
          <button class="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto w-20" @click="regestenStore.active_data.convents.splice(index, 1)">-</button>

        </div>
            <button type="button" class="col-start-1 hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-blue-800 p-1 mt-auto mb-auto" @click="toggleConventPopup()">Kovent hinzufügen</button>

        </div>


      <SaveTemplate v-if="onsave" :savemode="savemode" @close-popup="this.onsave = false"
                    @savedone="this.onsave = false" @saveerror="abortSave">
      </SaveTemplate>

        <AddPersonTemplate @add-person="addPerson" @close-popup="togglePersonPopup()" v-if="showPersonPopup"
            :regestenData="regestenStore.active_data" />
      <AddConventsTemplate @add-convent="addConvent" @close-popup="toggleConventPopup()" v-if="showConventPopup" :conventsList="conventsList"/>

      <AddTopoTemplate v-if="showTopoPopup" @add-topo="addTopo" @close-popup="toggleTopoPopup()" />
      <DeletePopUp v-if="showDeletePopup" :regestid="active_id" @close-popup="toggleDeletePopup"/>

    </div>
</template>
<script>
//Third party
import {mapState} from "pinia";

//components
import AddPersonTemplate from "@/components/popuptemplates/AddPersonTemplate.vue";
import AddTopoTemplate from "@/components/popuptemplates/AddTopoTemplate.vue";
import AddConventsTemplate from "@/components/popuptemplates/AddConventTemplate.vue";
import SaveTemplate from "@/components/saves/SaveTemplate.vue";

//stores
import {personListStore} from "@/stores/personList";
import {personStore} from "@/stores/person";
import {regestenStore} from "@/stores/regesten";
import {topoListStore} from "@/stores/topoList";

import {legalterms, sourcetypes, districts, datetypes} from "@/constanten";
import DeletePopUp from "@/components/popuptemplates/DeletePopUp.vue";

export default {
    name: "RegestenEditor.vue",
    components: {AddConventsTemplate, AddTopoTemplate, SaveTemplate, AddPersonTemplate, DeletePopUp},
    props:  ['conventsList'],
    emits: ["changeSourceType", "changeLegalTerm"],
    data() {
        return {
          legalterms: legalterms,
          sourcetypes: sourcetypes,
          districts: districts,
          datetypes: datetypes,

          savemode: "",
          personsearchstring: "",
          personensearchresult: "",

          selectedbibltype: null,

          showPersonPopup: false,
          showDeletePopup: false,
          showRelationPopup: false,
          showConventPopup: false,
          showTopoPopup: false,
          showSavedPopup: false,

          activePerson: false,
          activeRelation: false,
          activeDelete: false,
          activeSaved: false,

          onsave: false,
          awaitechange: false,
          gotoid: '',
        }

    },
  computed:{

    personListStore: function (){ return personListStore(); },
    ...mapState(personListStore,[
      'personlist'
    ]),

    personStore: function (){ return personStore(); },
    ...mapState(personStore,[
      'temp_data'
    ]),

    regestenStore: function(){ return regestenStore(); },
    ...mapState(regestenStore, [
      'regestenList',
      'active_id'
    ]),

    topoListStore: function (){ return topoListStore(); }


  },


    watch: {
      onsave(){
        if(this.onsave === false && this.awaitechange === true){
          this.$router.push({ path: '/'})
        }
      }
    },


    created() {
     //   this.createSelectedBiblType()
    },

    methods: {

        createSelectedBiblType: function () {
            for (var i = 0; i < this.regestenStore.active_data.bibls.length; i++) {
                this.selectedbibltype = this.regestenStore.active_data.bibls[i].type.value
            }
        },


        toggleRelationPopup: function() {
            this.showRelationPopup = !this.showRelationPopup;
            this.activeRelation = !this.activeRelation;
        },
        toggleDeletePopup: function() {
            this.showDeletePopup = !this.showDeletePopup;
            this.activeDelete = !this.activeDelete;
        },
        togglePersonPopup: function() {
            this.showPersonPopup = !this.showPersonPopup;
            this.activePerson = !this.activePerson;
        },
        toggleSavedPopup: function() {
            this.showSavedPopup = !this.showSavedPopup;
            this.activeSaved = !this.activeSaved;
        },
        toggleTopoPopup: function() {
            this.showTopoPopup = !this.showTopoPopup;
        },
      toggleConventPopup: function(){
        this.showConventPopup = !this.showConventPopup;
      },

        goBack: function() {
            this.$router.go(-1)
        },

        saveRegest: async function(savemode) {
          this.savemode = savemode
          this.onsave = true;
        },


        deleteLastChildPersons: function () {
            var lastChild = document.getElementById("persons").lastChild;

            if (!(lastChild.nodeName == "BUTTON")) {
                lastChild.remove()
            }
        },
        openPopup() {
            document.getElementById("addpersonpopup").style.display = "block";
            document.getElementById("black-bg").style.display = "block";
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        openDeletePopup() {
            document.getElementById("adddeletepopup").style.display = "block";
            document.getElementById("black-bg").style.display = "block";
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        closePopup() {

            var perPopup = document.getElementById("addpersonpopup");
            var delPopup = document.getElementById("adddeletepopup")

            if (perPopup.style.display === "block")
                perPopup.style.display = "none";
            else if (delPopup.style.display === "block")
                delPopup.style.display = "none";
            document.getElementById("black-bg").style.display = "none";
        },

        enableDelButton() {
            var delCheck = document.getElementById("delCheck");
            var delButton = document.getElementById("delButton");

            if (delCheck.checked) {
                delButton.disabled = false;
            }
            else {
                delButton.disabled = true;
            }
        },
      changeeditor: function(id) {
            this.$router.push({ path: '/editor/person/' + id })
        },

      addPerson: function(person) {
          let newpersonobject = { id: person.id, label:'', type: '', dead: false, reftype:{type: 'name', nr:''} }
          this.regestenStore.active_data.persons.push(newpersonobject)
          this.personStore.resetTempData();
          this.togglePersonPopup()
        },

      addTopo: function(newtopo) {
          this.regestenStore.addTopo(newtopo);
          this.topoListStore.resetSearchResult();
          this.showTopoPopup = false;
      },

      addConvent: function(convent){
          this.regestenStore.active_data.convents.push(convent);
          this.toggleConventPopup()
        console.log(convent);
      },



        changeDateType(e) {
            if (e.target.value == 'when' && !Object.prototype.hasOwnProperty.call(this.regestenStore.active_data.date.normalizeddate, 'when')) {
              this.regestenStore.resetDateTypeWhen();
            }
            else if (e.target.value == 'fromto' && !Object.prototype.hasOwnProperty.call(this.regestenStore.active_data.date.normalizeddate, 'from')) {
              this.regestenStore.resetDateTypePeriod();
            }
        },

      abortSave(){
          this.onsave = false
      },


      goTo: function() {
        this.onsave = true;
        this.changeurl = '/';
        this.awaitechange = true;
        this.savemode = 'all';
      },


      getConventName(id){
        let convent =  this.conventsList.filter(obj => {return obj.id == id })[0].name
        return convent
      }
  }

}
</script>
<style scoped></style>